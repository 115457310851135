import { Button, Flex } from 'antd';

import styled from '@emotion/styled';

export const Container = styled(Flex)`
  padding: 16px 0;
`;

export const ButtonSubmit = styled(Button)`
  && {
    height: 44px;
  }
`;

import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Typography,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAdminBillingDocumentController_delete } from '@api-client/generated/AdminBillingDocumentController/delete';
import { useAdminBillingDocumentController_findOneById } from '@api-client/generated/AdminBillingDocumentController/findOneById';
import { useAdminBillingDocumentController_updateOneById } from '@api-client/generated/AdminBillingDocumentController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { IconTrash } from '@assets';
import { Loader } from '@components';
import { currencyCodes } from '@constants';
import { FilePreview, PopupDelete } from '@entities';
import { useAccount } from '@hooks';

import ModalBillingUploadDocument from '../../modals/ModalBillingUploadDocument';
import BillingTabDocumentEmpty from '../BillingTabDocumentEmpty';
import * as S from './styled';

type BillingTabDocumentProps = {
  periodId: string;
  documentId: string | null;
  onAfterCreate: (id: string) => void;
  onAfterDelete: VoidFunction;
};

const { Title } = Typography;

const BillingTabDocument = (props: BillingTabDocumentProps) => {
  const { companyId } = useAccount();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [isModalUploadVisible, setIsModalUploadVisible] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null
  );
  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);

  const { data: document, isPending: isLoading } =
    useAdminBillingDocumentController_findOneById({
      params: {
        companyId: companyId!,
        id: selectedDocumentId!,
      },
      config: {
        enabled: !!selectedDocumentId,
      },
    });

  const { mutate: updateDocument } =
    useAdminBillingDocumentController_updateOneById();

  const { mutate: deleteDocument } = useAdminBillingDocumentController_delete();

  useEffect(() => {
    if (document) {
      const issueDate = document.documentMetadata?.issueDate;
      const dueDate = document.documentMetadata?.dueDate;

      form.setFieldsValue({
        isPaid: document.isPaid,
        documentMetadata: document.documentMetadata,
      });

      if (issueDate) {
        form.setFieldValue(['documentMetadata', 'issueDate'], dayjs(issueDate));
      }

      if (dueDate) {
        form.setFieldValue(['documentMetadata', 'dueDate'], dayjs(dueDate));
      }
    }
  }, [form, document]);

  useEffect(() => {
    setSelectedDocumentId(props.documentId);
  }, [props.documentId]);

  const handleAfterCreate = (id: string) => {
    setIsModalUploadVisible(false);
    setSelectedDocumentId(id);

    props.onAfterCreate(id);
  };

  const handleDeleteDocument = () => {
    deleteDocument(
      {
        parameter: {
          companyId: companyId!,
          id: selectedDocumentId!,
        },
      },
      {
        onSuccess: () => {
          setIsOpenPopupConfirm(false);
          setSelectedDocumentId(null);

          props.onAfterDelete();

          navigate(`/billing/${props.periodId}`);
        },
      }
    );
  };

  const handleSubmit = (values: Schemas.AdminUpdateBillingDocumentDto) => {
    updateDocument(
      {
        parameter: {
          companyId: companyId!,
          id: selectedDocumentId!,
        },
        requestBody: values,
      },
      {
        onSuccess: () => {
          message.success('The document has been updated successfully');
        },
      }
    );
  };

  if (!selectedDocumentId) {
    return (
      <>
        <ModalBillingUploadDocument
          periodId={props.periodId}
          open={isModalUploadVisible}
          onCancel={() => setIsModalUploadVisible(false)}
          onAfterCreate={handleAfterCreate}
        />

        <BillingTabDocumentEmpty
          onUpload={() => setIsModalUploadVisible(true)}
        />
      </>
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <S.Container gap={32}>
      <PopupDelete
        open={isOpenPopupConfirm}
        title="Are you sure you want to delete the document?"
        confirmParams={{
          text: 'Yes, delete',
        }}
        onRemove={handleDeleteDocument}
        onCancel={() => setIsOpenPopupConfirm(false)}
      />

      {document && (
        <FilePreview
          width="440px"
          height="662px"
          file={
            {
              mimeType: document.mimeType,
              url: document.url,
            } as Schemas.BillingDocument
          }
        />
      )}

      <Flex gap={16} vertical>
        <Flex align="center" justify="space-between">
          <Title level={5}>Document details</Title>
          <Button
            type="link"
            size="large"
            icon={<IconTrash />}
            onClick={() => setIsOpenPopupConfirm(true)}
            danger
          />
        </Flex>

        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item label="Number" name={['documentMetadata', 'number']}>
            <Input size="large" />
          </Form.Item>

          <Row gutter={[12, 0]}>
            <Col span={12}>
              <Form.Item
                label="Issue date"
                name={['documentMetadata', 'issueDate']}
              >
                <DatePicker
                  placeholder="DD.MM.YYYY"
                  format="DD.MM.YYYY"
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Due date"
                name={['documentMetadata', 'dueDate']}
              >
                <DatePicker
                  placeholder="DD.MM.YYYY"
                  format="DD.MM.YYYY"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[12, 0]}>
            <Col span={12}>
              <Form.Item label="Amount" name={['documentMetadata', 'amount']}>
                <InputNumber size="large" style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Currency"
                name={['documentMetadata', 'currencyCode']}
              >
                <Select
                  options={currencyCodes.map((code) => ({
                    label: code,
                    value: code,
                  }))}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Is paid "
            valuePropName="checked"
            layout="horizontal"
            colon={false}
            name="isPaid"
          >
            <Switch />
          </Form.Item>

          <Form.Item noStyle>
            <S.ButtonSubmit type="primary" htmlType="submit" size="large" block>
              Save
            </S.ButtonSubmit>
          </Form.Item>
        </Form>
      </Flex>
    </S.Container>
  );
};

export default BillingTabDocument;

import {
  Button,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  type ModalProps,
} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { useAdminBillingPeriodController_updateOneById } from '@api-client/generated/AdminBillingPeriodController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { RangePicker } from '@entities';
import { useAccount } from '@hooks';

import * as S from './styled';

type BillingPeriod = Schemas.BillingPeriod;
type InvoiceItemDto = Schemas.InvoiceItemDto;
type InvoiceItem = Schemas.InvoiceItem;

type InvoiceItemWithClientId = InvoiceItem & { id?: number };

type FormFieldsDto = {
  period: [string, string] | string;
};

type ModalBillingActionProps = {
  details: BillingPeriod | null;
  selected: InvoiceItem | null;
  type: 'add' | 'edit';
  onCancel: () => void;
  onAfterUpdate: (updatedPeriod: BillingPeriod) => void;
} & ModalProps;

const ModalBillingAction = ({
  selected,
  details,
  type,
  open,
  onCancel,
  onAfterUpdate,
  ...rest
}: ModalBillingActionProps) => {
  const { companyId } = useAccount();
  const [form] = Form.useForm();

  const [periodType, setPeriodType] = useState('period');

  const { mutate: updateBilling } =
    useAdminBillingPeriodController_updateOneById();

  useEffect(
    () => {
      if (selected && type == 'edit') {
        if (selected.endDate) {
          form.setFieldsValue({
            ...selected,
            period: [dayjs(selected.startDate), dayjs(selected.endDate)],
          });

          setPeriodType('period');
        } else {
          form.setFieldsValue({
            ...selected,
            period: dayjs(selected.startDate),
          });

          setPeriodType('one-time');
        }
      }

      if (type == 'add') {
        form.resetFields();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected, type]
  );

  const handleSubmit = (values: InvoiceItemDto & FormFieldsDto) => {
    const body = {
      ...values,
      totalPrice: +values.totalPrice,
    };

    if (periodType === 'period') {
      body.startDate = values.period[0];
      body.endDate = values.period[1];
    }

    if (periodType === 'one-time' && values.period) {
      body.startDate = values.period[0];
      _.unset(body, 'endDate');
    }

    _.unset(body, 'period');

    if (details) {
      updateBilling(
        {
          parameter: {
            companyId: companyId!,
            id: details.id,
          },
          requestBody: {
            ...details,
            items:
              type == 'edit'
                ? details.items.map((item) =>
                    item.id === selected?.id ? body : item
                  )
                : ([
                    { ...body, id: details.items.length + 1 },
                    ...details.items,
                  ] as InvoiceItemWithClientId[]),
          },
        },
        {
          onSuccess: (response) => {
            onAfterUpdate(response);
            onCancel();

            setPeriodType('period');

            form.resetFields();
          },
        }
      );
    }
  };

  return (
    <Modal
      width={504}
      open={open}
      title={type === 'add' ? 'Add new service' : 'Edit service'}
      footer={null}
      closeIcon={null}
      onCancel={onCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <S.Inner gap={24} vertical>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            isPrivate: false,
          }}
        >
          <S.Wrapper>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input size="large" />
            </Form.Item>

            <Form.Item label="Description (optional)" name="description">
              <Input.TextArea rows={4} size="large" />
            </Form.Item>
          </S.Wrapper>

          <S.Wrapper>
            <Form.Item
              label="Price"
              name="totalPrice"
              rules={[{ required: true }]}
            >
              <Input size="large" />
            </Form.Item>
          </S.Wrapper>

          <S.Wrapper>
            <S.Types gap={8} align="center">
              <S.Type
                isSelected={periodType === 'period'}
                onClick={() => setPeriodType('period')}
              >
                Period
              </S.Type>

              <S.Type
                isSelected={periodType === 'one-time'}
                onClick={() => setPeriodType('one-time')}
              >
                One-time
              </S.Type>
            </S.Types>

            <Form.Item
              label="Billing period"
              name="period"
              rules={[{ required: true }]}
            >
              {periodType === 'period' && (
                <RangePicker
                  variant="outlined"
                  size="large"
                  style={{ width: '100%' }}
                />
              )}

              {periodType === 'one-time' && (
                <DatePicker
                  placeholder="DD.MM.YYYY"
                  format="DD.MM.YYYY"
                  size="large"
                  style={{ width: '50%' }}
                />
              )}
            </Form.Item>
          </S.Wrapper>

          <S.Submit>
            <Form.Item noStyle>
              <Flex justify="flex-end" gap={12}>
                <Button onClick={onCancel} size="large">
                  Cancel
                </Button>

                <Button type="primary" htmlType="submit" size="large">
                  {type === 'add' ? 'Add service' : 'Save'}
                </Button>
              </Flex>
            </Form.Item>
          </S.Submit>
        </Form>
      </S.Inner>
    </Modal>
  );
};

export default ModalBillingAction;

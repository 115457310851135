import { Schemas } from '@api-client/generated/types';

import BillingServicesList from '../../services-list/BillingServicesList';

type BillingTabDetailsProps = {
  dataSource: Schemas.InvoiceItem[];
  onAdd: VoidFunction;
  onEdit: (details: Schemas.InvoiceItem) => void;
};

const BillingTabDetails = ({
  dataSource,
  onAdd,
  onEdit,
}: BillingTabDetailsProps) => (
  <BillingServicesList dataSource={dataSource} onEdit={onEdit} onAdd={onAdd} />
);

export default BillingTabDetails;

import { message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { Schemas } from '@api-client/generated/types';
import { IconDownload, IconZoomIn, IconZoomOut } from '@assets';
import { Loader } from '@components';
import { usePDFScaling } from '@hooks';

import * as S from './styled';

type File = Schemas.Document;
type TaskFile = Schemas.TaskFile;
type CompanyFile = Schemas.CompanyFile;
type PayslipDocument = Schemas.PayslipDocument;
type BillingDocument = Schemas.BillingDocument;

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

type FileViewerProps = {
  selectedFile?:
    | File
    | CompanyFile
    | TaskFile
    | PayslipDocument
    | BillingDocument;
  loading?: boolean;
  width?: string;
  height?: string;
};

const FileViewer: FC<FileViewerProps> = ({
  selectedFile,
  loading,
  width,
  height,
}) => {
  const [file, setFile] = useState<string | File | null>();

  const initialWidth = 405;
  const stepSize = 0.3;
  const magnifierMax = 4;

  const {
    scale,
    newScale,
    pageVisible,
    scaleUp,
    scaleDown,
    scaleReset,
    togglePageVisibility,
  } = usePDFScaling(initialWidth, stepSize, magnifierMax);

  useEffect(
    () => {
      if (selectedFile) {
        fetch(selectedFile.url)
          .then((response) => {
            setFile(null);
            return response.blob();
          })
          .then((blob) => {
            setFile(URL.createObjectURL(blob));
            scaleReset();
          })
          .catch(() => {
            message.error('Error fetching PDF');
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFile]
  );

  const handleDownload = () => {
    if (file instanceof Blob) {
      const link = document.createElement('a');

      link.href = URL.createObjectURL(file);
      link.download =
        // @ts-expect-error-next-line
        selectedFile?.name || selectedFile?.fileName || 'document.pdf';
      link.click();

      URL.revokeObjectURL(link.href);
    } else if (typeof file === 'string') {
      const link = document.createElement('a');

      link.href = file;
      link.download =
        // @ts-expect-error-next-line
        selectedFile?.name || selectedFile?.fileName || 'document.pdf';
      link.click();
    }
  };

  return (
    <S.Viewer>
      <S.Wrapper width={width} height={height}>
        <S.DocumentContainer>
          {loading ? (
            <Loader />
          ) : (
            <Document
              file={file}
              loading={<Loader />}
              error="Failed to load PDF file"
              noData=""
            >
              <S.Visible visible={pageVisible} reverse={false}>
                <Page
                  key={`front_page_${scale}`}
                  pageNumber={1}
                  scale={pageVisible ? scale : newScale}
                  loading=""
                  width={initialWidth}
                  onRenderSuccess={
                    pageVisible ? () => null : () => togglePageVisibility()
                  }
                />
              </S.Visible>
              <S.Visible visible={pageVisible} reverse={true}>
                <Page
                  key={`back_page_${scale}`}
                  pageNumber={1}
                  scale={pageVisible ? newScale : scale}
                  loading=""
                  width={initialWidth}
                  onRenderSuccess={
                    pageVisible ? () => togglePageVisibility() : () => null
                  }
                />
              </S.Visible>
            </Document>
          )}
        </S.DocumentContainer>
      </S.Wrapper>

      {!loading && file && (
        <S.Panel align="center">
          <S.PanelButton type="link" icon={<IconZoomIn />} onClick={scaleUp}>
            Zoom in
          </S.PanelButton>
          <S.PanelButton type="link" icon={<IconZoomOut />} onClick={scaleDown}>
            Zoom out
          </S.PanelButton>
          <S.PanelButton
            type="link"
            icon={<IconDownload />}
            onClick={handleDownload}
          >
            Download
          </S.PanelButton>
        </S.Panel>
      )}
    </S.Viewer>
  );
};

export default FileViewer;

import { Flex } from 'antd';

import styled from '@emotion/styled';

export const Container = styled(Flex)`
  width: 100%;
  height: calc(100vh - 370px);
  text-align: center;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
  margin-bottom: 24px;
`;

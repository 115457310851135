import { Flex } from 'antd';

import styled from '@emotion/styled';

export const Container = styled(Flex)`
  width: 100%;
`;

export const Skeleton = styled.div<{
  width?: number;
  height?: number;
  radius?: number;
}>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ radius }) => `${radius}px`};
`;

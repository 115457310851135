import { Flex } from 'antd';

import styled from '@emotion/styled';

export const Inner = styled(Flex)`
  .ui-form-item {
    margin-bottom: 16px;
  }
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrayLight};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 12px;
`;

export const Submit = styled.div`
  margin-top: 20px;
`;

export const Types = styled(Flex)`
  margin-bottom: 16px;
`;

export const Type = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected: boolean;
}>`
  height: 34px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.bgDarkBlue : theme.colors.white};
  border-radius: 34px;
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.bgDarkBlue : theme.colors.bgDarkGrey};
  cursor: pointer;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.white : theme.colors.blackSecondary};
  font-weight: 500;
  padding: 6px 12px;
`;

import { List } from 'antd';
import _ from 'lodash';

import { Schemas } from '@api-client/generated/types';
import { DEFAULT_CURRENCY_CODE } from '@constants';
import { Amount } from '@entities';

import BillingServicesListEmpty from '../BillingServicesListEmpty';
import BillingServicesListItem from '../BillingServicesListItem';
import * as S from './styled';

type InvoiceItem = Schemas.InvoiceItem;

type BillingServicesListProps = {
  dataSource: InvoiceItem[];
  onEdit: (details: InvoiceItem) => void;
  onAdd: VoidFunction;
};

const BillingServicesList = ({
  dataSource,
  onEdit,
  onAdd,
}: BillingServicesListProps) => {
  if (!dataSource.length) {
    return <BillingServicesListEmpty onAdd={onAdd} />;
  }

  return (
    <S.Container>
      <S.Header align="center" justify="space-between">
        <S.HeaderColumn>Description</S.HeaderColumn>
        <S.HeaderColumn>Price</S.HeaderColumn>
      </S.Header>

      <List
        dataSource={dataSource}
        renderItem={(item) => (
          <BillingServicesListItem details={item} onEdit={onEdit} />
        )}
        locale={{
          emptyText: <div />,
        }}
      />

      <S.Total align="center" justify="flex-end" gap={16}>
        <S.TotalTitle>Total</S.TotalTitle>

        <Amount
          currencyCode={DEFAULT_CURRENCY_CODE}
          amount={_.sumBy(dataSource, 'totalPrice')}
          withoutType
        />
      </S.Total>
    </S.Container>
  );
};

export default BillingServicesList;

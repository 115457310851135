import { Col, Flex, Row, Typography } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconEditUnderline } from '@assets';

import * as S from './styled';

type CompanyDetailsPersonProps = {
  details?: Schemas.FeaturesStatusDto;
  onEdit: () => void;
};

const { Text } = Typography;

const CompanyDetailsPerson: FC<CompanyDetailsPersonProps> = ({
  details,
  onEdit,
}) => (
  <Row>
    <Col span={12}>
      <S.Container gap={8} vertical>
        <S.Edit onClick={onEdit}>
          <IconEditUnderline width={16} height={16} /> Edit
        </S.Edit>

        <Flex justify="space-between" align="center" gap={12}>
          <Text>Invoice constructor</Text>

          {details?.invoiceConstructor ? (
            <S.Status color="success">Enabled</S.Status>
          ) : (
            <S.Status color="default">Disabled</S.Status>
          )}
        </Flex>
        <Flex justify="space-between" align="center" gap={12}>
          <Text>Infinite transactions</Text>

          {details?.infiniteTransactions ? (
            <S.Status color="success">Enabled</S.Status>
          ) : (
            <S.Status color="default">Disabled</S.Status>
          )}
        </Flex>
      </S.Container>
    </Col>
  </Row>
);

export default CompanyDetailsPerson;

import { Flex, Upload } from 'antd';

import styled from '@emotion/styled';

export const File = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  border-radius: 10px;
  padding: 12px 16px;
`;

export const Dragger = styled(Upload.Dragger)`
  width: 100%;

  div {
    width: 100%;
  }
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.blackSecondary};
  font-weight: 600;
  font-size: 16px;
  padding: 20px 0;
`;

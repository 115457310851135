import { Button } from 'antd';

import * as S from './styled';

type BillingServicesListEmptyProps = {
  onAdd: VoidFunction;
};

const BillingServicesListEmpty = ({ onAdd }: BillingServicesListEmptyProps) => (
  <S.Container align="center" justify="center" vertical>
    <S.Title>There are no services yet</S.Title>

    <S.Description>
      Add services for this billing period to generate invoices and other
      payment documents
    </S.Description>

    <Button onClick={onAdd} size="large" ghost>
      Add service
    </Button>
  </S.Container>
);

export default BillingServicesListEmpty;

import { Flex, Tag, type TagProps } from 'antd';

import styled from '@emotion/styled';

export const Container = styled(Flex)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 36px 20px 20px 20px;
  position: relative;
`;

export const Edit = styled.a`
  line-height: 1;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 10px;
  right: 20px;
`;

export const Status = styled(Tag)<TagProps>`
  line-height: 24px;
  font-size: 12px;
  margin-inline-end: 0;
`;

import { Flex } from 'antd';

import styled from '@emotion/styled';

export const Item = styled(Flex, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected: boolean;
}>`
  min-height: 80px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  padding: 16px;
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.primary : theme.colors.strokeDarkGrey};
  box-shadow: 0 0 0 1px
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.primary : 'transparent'}
    inset;
  clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 0 100%);
  position: relative;
  margin-bottom: 8px;

  &:after {
    content: '';
    width: 28px;
    height: ${({ isSelected }) => `${isSelected ? 2 : 1}px`};
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.primary : theme.colors.strokeDarkGrey};
    position: absolute;
    top: 9px;
    right: -5px;
    z-index: 2;
    transform: rotate(45deg);
  }
`;

export const Title = styled.div`
  font-weight: 600;
  flex: 1;
`;

export const Status = styled(Flex)<{ status?: 'isSuccess' }>`
  color: ${({ theme, status }) =>
    status === 'isSuccess' ? theme.colors.success : theme.colors.gray};
  font-weight: 600;
  font-size: 12px;
`;

export const ServicesCount = styled.div`
  font-size: 12px;
`;

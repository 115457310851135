import { Button } from 'antd';

import { IconPlus } from '@assets';

import * as S from './styled';

type BillingTabDocumentEmptyProps = {
  onUpload: VoidFunction;
};

const BillingTabDocumentEmpty = ({
  onUpload,
}: BillingTabDocumentEmptyProps) => (
  <S.Container align="center" justify="center" vertical>
    <S.Title>There are no invoices yet</S.Title>

    <S.Description>
      Keep your records organized by attaching payment document.
    </S.Description>

    <Button icon={<IconPlus />} onClick={onUpload} size="large" ghost>
      Upload invoice
    </Button>
  </S.Container>
);

export default BillingTabDocumentEmpty;

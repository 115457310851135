import { Flex } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconCheck } from '@assets';
import { DEFAULT_CURRENCY_CODE } from '@constants';
import { Amount } from '@entities';

import * as S from './styled';

type BillingListItemProps = {
  selectedId: string;
  details: Schemas.BillingPeriod;
};

const BillingListItem = ({ selectedId, details }: BillingListItemProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const handleSelect = (id: string) => {
    navigate(`/billing/${id}${search}`);
  };

  const getDatePeriod = (period: string) => dayjs(period).format('DD.MM.YYYY');

  return (
    <S.Item
      gap={6}
      isSelected={selectedId === details.id}
      onClick={() => handleSelect(details.id)}
      vertical
    >
      <Flex align="flex-start" justify="space-between" gap={8}>
        <S.Title>
          {getDatePeriod(details.startDate)} - {getDatePeriod(details.endDate)}
        </S.Title>

        {details.billingDocument ? (
          <S.Status status="isSuccess" align="center" gap={4}>
            Issued
            <IconCheck />
          </S.Status>
        ) : (
          <S.Status>Ongoing</S.Status>
        )}
      </Flex>

      <S.ServicesCount>{details.items.length} services</S.ServicesCount>

      <Amount
        currencyCode={DEFAULT_CURRENCY_CODE}
        amount={_.sumBy(details.items, 'totalPrice')}
        withoutType
      />
    </S.Item>
  );
};

export default BillingListItem;

import { Flex } from 'antd';

import { Schemas } from '@api-client/generated/types';
import { IconEditUnderline } from '@assets';
import { DEFAULT_CURRENCY_CODE } from '@constants';
import { Amount } from '@entities';

import * as S from './styled';

type InvoiceItem = Schemas.InvoiceItem;

type BillingServicesListItemProps = {
  details: InvoiceItem;
  onEdit: (details: InvoiceItem) => void;
};

const BillingServicesListItem = ({
  details,
  onEdit,
}: BillingServicesListItemProps) => (
  <S.Item gap={6} justify="center" vertical>
    <Flex align="center" justify="space-between" gap={16}>
      <Flex vertical>
        <S.Title>{details.name}</S.Title>
        {details.description}
      </Flex>

      <Amount
        className="service-amount"
        currencyCode={DEFAULT_CURRENCY_CODE}
        amount={details.totalPrice}
        withoutType
      />

      <S.ButtonEdit
        className="service-button-edit"
        type="link"
        icon={<IconEditUnderline />}
        size="large"
        onClick={() => onEdit(details)}
      />
    </Flex>
  </S.Item>
);

export default BillingServicesListItem;

import { List } from 'antd';
import { useParams } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';

import BillingListEmpty from '../BillingListEmpty';
import BillingListItem from '../BillingListItem';

type BillingListProps = {
  dataSource: Schemas.BillingPeriod[];
  onAdd: VoidFunction;
};

const BillingList = ({ dataSource, onAdd }: BillingListProps) => {
  const { id: billingId } = useParams();

  if (!dataSource.length) {
    return <BillingListEmpty onAdd={onAdd} />;
  }

  return (
    <List
      dataSource={dataSource}
      renderItem={(item) => (
        <BillingListItem selectedId={billingId!} details={item} />
      )}
    />
  );
};

export default BillingList;
